import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes';
import * as appTool from '@/utils/appTool.js';
import store from '@/store';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

//避免太多无效参数，router.query中只准许添加特定参数, 其他仍然保留在search中,
//datatype 投票链接使用
//atypeid, 资产类型id assetTypeId
const PARAMS = [
  'aid',
  'dataid',
  'from',
  'chatid',
  'npath',
  'tab',
  'tabType',
  'preTime',
  'opinionId',
  'oldTime',
  'voteid',
  'datatype',
  'businessCode',
  'userId',
  'paid',
  'tenantId',
  'objectId','app','shareid',
  'timeStamp',
  'routeViewType', // 视图类型，sjsj 数据收集消息卡片跳转数据收集台账用 / weekReport 投前、投后跳转周报台账
  'datacollectTabId', // 数据收集视图tab选项卡 选中对应资产类型
  'pid', // 数据收集消息卡片跳转数据收集
  'weekReportId', // 周报 id
  'configid', // 线索收集卡片跳转
  'assetTypeId', // 线索收集卡片跳转
  'cluesResultId', // 线索收集卡片跳转
];

router.beforeEach((to, from)=>{
  // console.log(from);
  let newRoute;
  let queryObj;
  //先转换search字符串，添加到query中
  if(location.search && to.query.nquery != 1){  //如果还没转换过search( nquery==1表示转换过 )
    newRoute = {
      replace: true,
      query:{ ...to.query, nquery: 1 }
    }
    
    queryObj = location.search.slice(1).split('&');
    queryObj = queryObj.reduce((pre, cur)=>{ 
      let val = cur.split('=')[1];
      if(val){
        let key = cur.split('=')[0];
        pre[key] = val
      }
      return pre;
    },{})
    //如果类型是h5，即打开h5的页面
    if((queryObj.ntype == 'h5') && (location.pathname.indexOf('h5') == -1)){
      location.href = location.href.replace(location.origin, location.origin + '/h5');
      return false;
    }
    
    if (queryObj.npath) {
      if(['addvotenoauth','addvoteurl'].includes(queryObj.npath)){
        newRoute.path = `/` + queryObj.npath  
      }else{
        newRoute.path = `/${appTool.getAppName()}/` + queryObj.npath
      }
    } else {
      newRoute.path = to.path
    }
    //只准许添加特定参数
    PARAMS.forEach((ii, index)=>{
      if (queryObj[ii]) {
        newRoute.query[ii] = queryObj[ii]
      }
    })
  }
  // console.log(111, newRoute);
  //哪些路径path需要判断登录
  if (
    [
      'before',
      'after',
      'fund',
      'fundanalyze',
      'datacollect',
      'manage',
      'setnotify',
      'cloud',
      'helper',
      'getmessage',
      'lpinvestor',
      'publicSentiment',
      'setproject',
      'addvote',
      'appIntroduce',
      'cCapital', // c_capital定制
      'shareDetails',
      'test',
    ].includes(to.name)
  ) {
    // 投票不需要权限验证
    if(['addvotenoauth','addvoteurl'].includes(queryObj?.npath)){
      return newRoute || true
    }
    //如果有token，且没过期，继续下一步，否则跳转登录
    if (appTool.getToken() && !appTool.tokenExpire()) {
      store.dispatch('initStoreWhenLogin');
      return newRoute || true
    } else {
      // 获取hash的参数
      let queryMatch = location.hash.match(/([a-zA-Z_-]*)=([0-9a-zA-Z*_-]*)/gi)
      queryMatch =
        queryMatch.length < 1
          ? {}
          : queryMatch.reduce((pre, cur) => {
              let val = cur.split('=')[1]
              if (val) {
                let key = cur.split('=')[0]
                pre[key] = val
              }
              return pre
            }, {})

      let innerUrl = `${location.origin}/#/login/${appTool.getAppName()}?`
      localStorage.setItem('redirect_url', location.href)

      //添加特定参数，hash参数补充到search上
      PARAMS.forEach((ii, index) => {
        if (queryMatch[ii]) {
          innerUrl += `${index > 0 ? '&' : ''}${ii}=${queryMatch[ii]}`
        }
      })
      let url = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=${
        queryMatch.aid
      }&redirect_uri=${encodeURIComponent(innerUrl)}`
      // console.log(url);
      location.href = url
      return false
    }
  } else {
    // console.log('newRoute:' + newRoute)
    return newRoute || true
  }
})

export default router
