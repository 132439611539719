<template>
  <a-popover v-model:popup-visible="showAddCreater" trigger="click" position="br" class="cus-select-popover small person-select">
    <slot></slot>
    <template #content>
      <a-input ref="searchInput" v-model.trim="name" class="name-input" placeholder="搜索成员" allow-clear @input="searchUser" @clear="getUser" />
      <div class="record-type-popover-cont creater">
        <template v-if="personList.length">
          <template v-for="ii in personList" :key="ii.id">
            <div
              style="cursor: pointer;"
              @click="selectHandler(ii)"
              :class="['pop-select-item', creators.includes(ii.id) ? 'active-select' : '', ii.disabled ? 'disabled' : '']"
            >
              <div class="creater-cont">
                <div class="avatar-cont">
                  <img v-if="ii.avatar" class="avatar-img" :src="ii.avatar" width="32" alt="">
                  <span v-else class="avatar-text">{{ ii.name.slice(-2) }}</span>
                </div>
                <ul class="person-info">
                  <li class="name">{{ ii.name }}</li>
                  <li v-if="ii.deptNames" class="dpt">{{ ii.deptNames }}</li>
                </ul>
              </div>
              <i v-if="creators.includes(ii.id)" class="iconfont icon-close-med" />
            </div>
          </template>
        </template>
        <small v-else class="empty">未找到相关人员</small>
      </div>
    </template>
  </a-popover>
</template>

<script>
import { getAllUsers, getAllUsersByPinyin } from '@/services/investment/index.js'
import _ from 'lodash'
export default {
  name: "addPerson",
  props: {
    selfInfoId: String,
    persons: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      showAddCreater: false,
      creators: [],
      name: '',
      selectedPersons: [],
      personList: []
    }
  },
  watch: {
    persons: {
      deep: true,
      immediate: true,
      handler(v) {
        this.creators = []
        this.selectedPersons = v
        this.selectedPersons.forEach(item => {
          this.creators.push(item.id)
        })
      }
    },
    showAddCreater(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    searchUser: _.debounce(function(val) {
      this.getUser()
    }, 200),
    getUser() {
      getAllUsersByPinyin({
        data: {
          name: this.name
        }
      }).then(res => {
        res.forEach(item => {
          item.disabled = false
          if (item.id === this.selfInfoId) {
            item.disabled = true
          }
        })
        this.personList = res
      })
    },
    selectHandler(item) {
      if (item.disabled) return
      item['label'] = item.name
      item['color'] = '#F3F4F3'
      const index = this.creators.findIndex(ii => {
        return item.id == ii
      })
      if (index < 0) {
        this.creators.push(item.id)
        this.selectedPersons.push(item)
      } else {
        this.creators.splice(index, 1)
        this.selectedPersons.splice(index, 1)
      }

      this.$emit('getSelectPerson', this.selectedPersons)
    },
  },
}
</script>

<style lang="scss">
.person-select {
  .arco-popover-popup-content {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.empty {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  color: #86909C;
}
.name-input {
  width: 100%;
  height: 38px;
  padding: 0 8px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #CED3D6;
  :deep(.arco-input) {
    padding: 0;
  }
}
.add-creater-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  transition: all .2s;
  cursor: pointer;
  margin-top: 4px;
  &:hover {
    background: rgba(31, 35, 41, 0.08);
  }
}
.record-type-popover-cont {
  width: 144px;
  max-height: 50vh;
  overflow-y: auto;
  padding: 4px;
  &.creater {
    width: 210px;
    max-height: 298px;
    border-radius: 6px;
    .pop-select-item {
      height: 44px !important;
      line-height: 44px !important;
      justify-content: space-between;
    }
  }
  .creater-cont {
    display: flex;
    align-items: center;
    width: 160px;
    height: 38px;
    .person-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 119px;
      height: 38px;
      margin-left: 8px;
      .name {
        width: 100%;
        height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        line-height: 20px;
        color: #1F2329;
      }
      .dpt {
        height: 18px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        line-height: 18px;
        color: #86909C;
      }
    }
    .avatar-cont {
      width: 32px;
      height: 32px;
      text-align: center;
      overflow: hidden;
      border-radius: 50%;
      background: linear-gradient(180deg, #297AFF 0%, #0963F6 100%);
      .avatar-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .avatar-text {
        color: #fff;
        font-size: 12px;
        display: inline-block;
        scale: 0.75;
      }
    }
  }
  .pop-select-item {
    width: 100%;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed !important;
      .name {
        color: #c9cdd4;
      }
    }
  }
  .pop-select-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .active-select {
    display: flex;
    justify-content: space-between;
    color: #3272FE !important;
    .iconfont {
      width: 20px;
      padding-left: 4px;
      flex-shrink: 0;
    }
  }
}
</style>