<template>
  <!-- 如果from === 'message' 来着消息归档页面，则写死left -->
  <div id="mention-modal" class="at-someone" :style="{ top: top, left: from === 'message' ? '40px' : left, right: right, bottom: bottom}"> 
    <div class="at-someone-box" ref="atSomeoneBox">
      <div class="option-header" v-if="atType.length > 1">
        <span :class="['type-item', { active: currType == 'all' }]" @click="handleChangeType('all')"
          >全部</span
        >
        <span
          :class="['type-item', { active: currType == 'user' }]"
          @click="handleChangeType('user')"
          >人</span
        >
        <span
          :class="['type-item', { active: currType == 'file' }]"
          @click="handleChangeType('file')"
          >云文档</span
        >
      </div>
      <a-spin :loading="userLoading">
        <a-input id="mention-input" v-model="searchVal" placeholder="搜索" ref="input" @input="handleSearch" @blur="searchBlur" @focus="searchFocus" />
        <ul v-if="list.length" class="mention-option-ul">
          <li
            v-for="(item, index) in list"
            :key="index"
            :class="['mention-option-li ', { active: index == selectIndex }]"
            @click="insertMentionHandler(item)"
          >
            <span v-if="item.type == 'user'" class="mention-option-img-cont">
              <img
                v-if="item.userInfo.avatar"
                class="mention-option-img"
                :src="item.userInfo.avatar"
              />
              <span v-else class="mention-option-img-name">{{ item.name[0] }}</span>
            </span>
            <fileIcon v-else class="mention-option-img-file" :file="item.fileInfo" />
            <span class="mention-option-name">{{ item.name }}</span>
          </li>
        </ul>
        <div v-if="!userLoading && !list.length" class="top-team-search-empty">
          <small data-v-e44d7dbc="">没有匹配结果，换个关键词试试吧</small>
        </div>
      </a-spin>
    </div>
    <!-- <input id="mention-input" v-model="searchVal" ref="input" @keyup="inputKeyupHandler">
      <ul id="mention-list">
          <li
              v-for="item in searchedList"
              :key="item.id"
              @click="insertMentionHandler(item.id, item.name)"
          >{{item.name}}</li>
      </ul> -->
  </div>
</template>

<script>
import fileIcon from '@/components/fileIcon.vue'
import { getAllUsersByPinyin } from '@/services/investment/index.js'
import { searchFilesByUser, querySelfFileNoId } from '@/services/investment/document.js'
import { isHttpUrl } from '@/utils/url.js'
import { debounce } from "lodash";

export default {
  name: 'MentionModal',
  components: {
    fileIcon,
  },
  props: {
    atType:{    //支持@的类型，file文件，user人员
      type: Array,
      default: ['file', 'user']
    },
    from: String,
  },
  data() {
    return {
      // 定位信息
      top: '',
      left: '',

      // list 信息
      searchVal: '',
      list: [],
      userList: [],
      docList: [],
      userLoading: false,
      selectIndex: 0,
      currType: 'all',
      time: null,
    }
  },
  computed: {
    // 根据 <input> value 筛选 list
    searchedList() {
      const searchVal = this.searchVal.trim().toLowerCase()
      return this.list.filter((item) => {
        const name = item.name.toLowerCase()
        if (name.indexOf(searchVal) >= 0) {
          return true
        }
        return false
      })
    },
  },
  created() {
    this.userLoading = true
    this.handleSearch()
  },
  methods: {
    // inputKeyupHandler(event) {
    //   // esc - 隐藏 modal
    //   if (event.key === 'Escape') {
    //     this.$emit('hideMentionModal')
    //   }

    //   // enter - 插入 mention node
    //   if (event.key === 'Enter') {
    //     // 插入第一个
    //     const firstOne = this.searchedList[0]
    //     if (firstOne) {
    //       const { id, name } = firstOne
    //       this.insertMentionHandler(id, name)
    //     }
    //   }
    // },
    insertMentionHandler(item) {
      this.$emit('insertMention', item)
      this.$emit('hideMentionModal') // 隐藏 modal
    },
    searchBlur() {
      this.time = setTimeout(() => {
        this.$emit('hideMentionModal')
        this.$emit('editorBlurHandler')
        clearTimeout(this.time)
        this.time = null
      }, 300);
    },
    searchFocus() {
      let time = setTimeout(() => {
        this.$emit('editorFocusHandler')
        clearTimeout(time)
        time = null
      }, 300);
    },
    handleSearch: debounce(async function() {
      this.$nextTick(() => {
        this.$refs?.input?.focus()
      })
      this.list = [];
      this.userLoading = true
      if(this.atType.includes('user')){
        let userRes = await getAllUsersByPinyin({
          data: {
            name: this.searchVal
          }
        });
        this.userList = (userRes || []).map((ii)=>{
          return {
            name: ii.name,
            type: 'user',
            userInfo: ii
          }
        });
      }
      if(this.atType.includes('file')){
        let docRes;
        if(!this.searchVal){
          docRes = await querySelfFileNoId({
            params: {
              isCache: true
            }
          });
        }else{
          docRes = await searchFilesByUser({
            data:{
              searchKey: this.searchVal,
              currentPage: 1,
              pageSize: 20
            }
          })
        }
        
        this.docList = (docRes || []).map((ii)=>{
          return {
            name: ii.fileName,
            type: 'file',
            fileInfo: ii
          }
        });
      }

      this.userLoading = false;
      this.selectIndex = -1;

      if(isHttpUrl(this.searchVal) && this.docList.length == 1){
        this.urlDocMap[this.searchVal] = this.docList[0];
        this.handleSelect(this.docList[0]);
        return;
      }

      if(this.currType == 'all'){
        this.list = this.userList.slice(0,10).concat(this.docList.slice(0,10));
      }else if(this.currType == 'user'){
        this.list = this.userList;
      }else if(this.currType == 'file'){
        this.list = this.docList;
      }else{}
      
    }, 500),
    handleChangeType(type){
      if (this.time) {
        clearTimeout(this.time)
        this.time = null
      }
      this.selectIndex = -1;
      this.currType = type;
      if(type == 'user'){
        this.list = this.userList;
      }else if(type == 'file'){
        this.list = this.docList;
      }else if(type == 'all'){
        this.list = this.userList.slice(0,10).concat(this.docList.slice(0,10));
      }else{}
      this.$refs.input.focus()
    },
    handleSelect (item) {
      this.closeEdit();
      const {name} = item
      this.showFlag = 'hidden'
      this.$emit('updataShowFlag', false)
      //获取选区对象
      let selection = this.position.selection
      let range = this.position.range

      let spanNode1;
      //如果选择了人
      if(item.type == 'user'){
        // 生成需要显示的内容，包括一个 span 和一个空格。
        spanNode1 = document.createElement('span')
        spanNode1.className = 'at-text'
        spanNode1.innerHTML = '@' + name
        spanNode1.dataset.id = item.userInfo.id
        //  设置@人的节点不可编辑
        spanNode1.contentEditable = false
      }else if(item.type =='file'){
        // 生成需要显示的内容，包括一个 span 和一个空格。
        spanNode1 = document.createElement('a')
        spanNode1.className = 'at-text at-file'
        spanNode1.setAttribute('href', item.fileInfo.url);
        spanNode1.setAttribute('target', '_blank');
        spanNode1.innerHTML = `@` + name
        // spanNode1.innerHTML = `<i class="iconfont ${this.getFileIconByType(item.fileInfo)}" style="font-size: 12px" />` + name
        spanNode1.dataset.fileToken = item.fileInfo.fileToken
        spanNode1.dataset.fileName = item.fileInfo.fileName
        spanNode1.dataset.fileTags = item.fileInfo.fileTags ||'9999'
        spanNode1.dataset.fileType = item.fileInfo.fileType
        //  设置@人的节点不可编辑
        spanNode1.contentEditable = false
      }

      let spanNode2 = document.createElement('p');
      spanNode2.innerHTML = '&nbsp;';
      // 将生成内容打包放在 Fragment 中，并获取生成内容的最后一个节点，也就是空格。
      //创建一个新的空白的文档片段
      let frag = document.createDocumentFragment(),
          node, lastNode;
      frag.appendChild(spanNode1)
      lastNode = frag.appendChild(spanNode2.firstChild)

      //先定位@符号
      // let editRange = this.editor.selection._currentRange;
      let editRange = this.position.range;
      let selectContainer = editRange.commonAncestorContainer

      if(selectContainer.nodeName == '#text'){  //如果是普通文本
        let oldVal = Array.from(selectContainer.nodeValue || '');

        let offsetIndex = editRange.endOffset;
        let atIndex = oldVal.lastIndexOf('@', offsetIndex)

        if(atIndex > -1){
          
          let child = editRange.commonAncestorContainer;
          let beforeText = oldVal.slice(0, atIndex).join('');
          let afterText = oldVal.slice(offsetIndex).join('');
          
          if(beforeText){
            child.before(beforeText);
          }
          if(afterText){
            child.after(afterText);
          }
          child.replaceWith(frag);
          // parent.replaceChildren(...tmpArr);
        }
      }

      // 将 Fragment 中的内容放入 range 中，并将光标放在空格之后。
      // range.insertNode(frag)
      selection.collapse(lastNode, 1)
  
      //将当前的选区折叠到最末尾的一个点
      selection.collapseToEnd();

      this.updateRecordHtml();
    },
  },
  mounted() {
    // 获取光标位置
    const viewWidth = window.innerWidth
    const viewHeight = window.innerHeight
    const modalHeight = 310 // modal高度310
    const domSelection = document.getSelection()
    const domRange = domSelection?.getRangeAt(0)
    if (domRange == null) return
    const rect = domRange.getBoundingClientRect()

    // 定位 modal
    this.top = `${rect.top + 20}px`
    this.left = `${rect.left + 5}px`
    if (viewWidth < rect.left + 210) {
      this.right = '64px'
      this.left = 'auto'
    }
    if (viewHeight < rect.top + modalHeight) {
      this.bottom = '10px'
      this.top = 'auto'
    }

    // focus input
    // this.$refs.input.focus()
  },
}
</script>

<style lang="scss" scoped>
.at-someone {
  position: fixed;
  width: 210px;
  border-radius: 4px;
  background: #fff;
  padding: 0 0 8px 0;
  border: 1px solid #E4E5EC;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  z-index: 1000;
  transition: all .3s;
  :deep(.arco-input-wrapper) {
    border: 0;
    // border-color: #E4E5EC;
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 0;
    &:hover {
      background: #fff;
    }
  }
}
.at-someone-box{
  .option-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 38px;
    border-bottom: 1px solid #dbdfe1;
    .type-item{
      margin: 0 10px;
      font-size: 14px;
      line-height: 38px;
      border-bottom: 3px solid rgba(0,0,0,0);
      cursor: pointer;
      &.active{
        color: #3272FE;
        border-bottom: 3px solid #3272FE;
      }
    }
  }
  .mention-option-ul {
    min-width: 200px;
    height: 226px;
    overflow-y: auto;
    .mention-option-li {
      width: 100%;
      height: 32px;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      user-select: none;
      &:hover,
      &.active {
        background: #f9fafc;
        border-radius: 4px;
      }
      .mention-option-img-file{
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .mention-option-img-cont {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
        .mention-option-img {
          width: 100%;
          object-fit: cover;
          display: block;
        }
        .mention-option-img-name {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #fff;
          background: linear-gradient(0deg, rgba(41, 122, 255, 1) 0%, rgba(9, 99, 246, 1) 100%);
        }
      }
      .mention-option-name {
        color: #2E3C46;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .mention-option-deptname {
        color: #929AA4;
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }
}
</style>
